* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

:root {
  font-size: 16px;
  --color-text: #fff;
  --color-bg: #a68cc9;
  --color-bg-alt: #a68cc9;
  --color-link: rgba(255, 255, 255, 0.5);
  --color-link-hover: #fff;
  --color-title: #e93f33;
  --primary-color: #c5057d;
}

html {
  font-family: "tenon", sans-serif;
  scroll-behavior: smooth;
}

body {
  background-color: var(--color-bg);
  /* font-family: "stinger-variable", sans-serif; */
}

/* End of styling Header section  */

/* Main container and section styling */
main {
  width: 100%;
}
section {
  width: 100%;

  padding-top: 9rem;
  width: 100%;
}

/* About us styling */

/* .about-us {
  margin: auto;
} */

.about-us_heading {
  width: 70%;
  font-family: "stinger-variable", sans-serif;
  font-variation-settings: "wdth" 140, "wght" 150;
  font-size: 2.5rem;
  font-style: italic;
  font-weight: bolder;
  padding-top: 1rem;
  text-align: center;
  color: var(--color-text);
  border-bottom: 3px solid var(--primary-color);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  margin: auto;
}

.about_content {
  margin-top: 4rem;
  background: var(--color-bg);
  width: 100%;
  display: flex;
  gap: 3rem;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  text-decoration: none;
  padding: 0 2rem;
  max-height: 1200px;
}

.about_img {
  width: 80%;
  border-radius: 40px;
}

.about_content .right {
  display: flex;
  justify-content: center;
  align-items: center;
}

.about_content blockquote {
  font-size: 1.1rem;
  color: var(--color-text);
  padding-bottom: 3rem;
  word-spacing: 7px;
  line-height: 1.5rem;
}

.about_content blockquote h4 {
  padding-top: 0.7rem;
}
/* FORM Styling */
.form-container {
  width: 400px;
  background: linear-gradient(#212121, #212121) padding-box,
    linear-gradient(145deg, transparent 35%, #e81cff, #40c9ff) border-box;
  border: 2px solid transparent;
  padding: 32px 24px;
  font-size: 14px;
  font-family: inherit;
  color: white;
  display: flex;
  flex-direction: column;
  gap: 20px;
  box-sizing: border-box;
  border-radius: 16px;
}

.form-container button:active {
  scale: 0.95;
}

.form-container .form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.form .three_c {
  display: flex;
  gap: 20px;
}

.form-container .form-group {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.form-container .form-group label {
  display: block;
  margin-bottom: 5px;
  color: #717171;
  font-weight: 600;
  font-size: 12px;
}

.form-container .form-group input {
  width: 100%;
  padding: 12px 16px;
  border-radius: 8px;
  color: #fff;
  font-family: inherit;
  background-color: transparent;
  border: 1px solid #414141;
}

.form-container .form-group textarea {
  width: 100%;
  padding: 12px 16px;
  border-radius: 8px;
  resize: none;
  color: #fff;
  height: 96px;
  border: 1px solid #414141;
  background-color: transparent;
  font-family: inherit;
}

.form-container .form-group input::placeholder {
  opacity: 0.5;
}

.form-container .form-group input:focus {
  outline: none;
  border-color: #e81cff;
}

.form-container .form-group textarea:focus {
  outline: none;
  border-color: #e81cff;
}

.form-container .form-submit-btn {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  align-self: flex-start;
  font-family: inherit;
  color: #717171;
  font-weight: 600;
  width: 40%;
  background: #313131;
  border: 1px solid #414141;
  padding: 12px 16px;
  font-size: inherit;
  gap: 8px;
  margin-top: 8px;
  cursor: pointer;
  border-radius: 6px;
}

.form-container .form-submit-btn:hover {
  background-color: #fff;
  border-color: #fff;
}

/* Footer styling */

footer {
  width: 100%;
  height: 20%;
  background-color: var(--primary-color);
  margin-top: 5rem;
  display: grid;
  place-items: center;
  padding-top: 2.5rem;
  padding-bottom: 4rem;
}

footer h1 {
  color: #fff;
}

footer ul li {
  list-style-type: none;
}

.social_links {
  display: flex;
  flex-direction: row;
  gap: 4rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

footer ul li a {
  text-decoration: none;
  font-size: 3rem;
  transition: 0.5s;
  color: #fff;
}

footer ul li a:hover {
  color: var(--color-bg);
}

.copyright {
  font-size: 1rem;
}

@media screen and (max-width: 1000px) {
  /* About us mobile */

  .about-us_heading {
    width: 100%;
    font-family: "stinger-variable", sans-serif;
    font-variation-settings: "wdth" 140, "wght" 150;
    font-size: 2rem;
    padding-top: 2.5rem;

    font-style: italic;
    font-weight: bolder;
    text-align: center;
  }
  .about_content {
    flex-direction: column;
  }

  .about_content .left {
    width: 100%;
  }

  .about_content .right {
    width: 100%;
  }

  /* Footer styling */

  footer {
    width: 100%;
    height: 20%;
    background-color: var(--primary-color);
    margin-top: 2rem;
    display: grid;
    place-items: center;
    padding-top: 2rem;
    padding-bottom: 4rem;
    padding-right: 2rem;
    padding-left: 2rem;
  }

  footer h1 {
    color: #fff;
  }

  footer ul li {
    list-style-type: none;
  }

  .social_links {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  footer ul li a {
    text-decoration: none;
    font-size: 2rem;
    transition: 0.5s;
    color: #fff;
  }

  footer ul li a:hover {
    color: var(--color-bg);
  }

  .copyright {
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 600px) {
  /* About us mobile */

  .about-us_heading {
    padding-top: 2rem;
    width: 100%;
    font-family: "stinger-variable", sans-serif;
    font-variation-settings: "wdth" 140, "wght" 150;
    font-size: 2rem;
    font-style: italic;
    font-weight: bolder;
    text-align: center;
  }
  .about_content {
    flex-direction: column;
    gap: 0;
  }

  .about_content .left {
    width: 100%;
  }

  .about_content .right {
    width: 100%;
  }

  .about_content blockquote {
    font-size: 1rem;
    color: var(--color-text);
    padding-bottom: 3rem;
    word-spacing: 7px;
    line-height: 1.5rem;
  }

  /* Footer styling */

  footer {
    width: 100%;
    height: 20%;
    background-color: var(--primary-color);
    margin-top: 5rem;
    display: grid;
    place-items: center;
    padding-top: 2rem;
    padding-bottom: 4rem;
    padding-right: 2rem;
    padding-left: 2rem;
  }

  footer h1 {
    color: #fff;
  }

  footer ul li {
    list-style-type: none;
  }

  .social_links {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  footer ul li a {
    text-decoration: none;
    font-size: 2rem;
    transition: 0.5s;
    color: #fff;
  }

  footer ul li a:hover {
    color: var(--color-bg);
  }

  .copyright {
    font-size: 0.8rem;
  }
}
