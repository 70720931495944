* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

:root {
  font-size: 16px;
  --color-text: #fff;
  --color-bg: #a68cc9;
  --color-bg-alt: #a68cc9;
  --color-link: rgba(255, 255, 255, 0.5);
  --color-link-hover: #fff;
  --color-title: #e93f33;
  --primary-color: #c5057d;
}

html {
  font-family: "tenon", sans-serif;
}

/* Begining of styling Header styling */

.logo a img {
  width: 8rem;
}
header {
  width: 100%;
  background: var(--color-bg);
  display: flex;
  justify-content: space-between;
  height: 100px;
  position: fixed;
  padding: 1rem 9%;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1000;
}
.nav-link {
  text-decoration: none;
  color: #fff;
  font-size: 18px;
  padding: 0;
  cursor: pointer;
}

.nav-link:hover {
  color: #6b1d87; /* Purple hover effect */
}

.contact_us {
  background-color: #6b1d87; /* Purple background for Contact Us */
  border-radius: 5px;
  color: white;
  padding: 10px 15px;
}

.contact_us:hover {
  background-color: #56126c; /* Darker purple on hover */
}

.header .navbar ul {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding-right: 2rem;
  cursor: pointer;
}

.header .navbar ul li {
  font-size: 1.7rem;
  color: #666;
  margin: 0 1rem;
}

.menu-icon {
  display: none;
  font-size: 2rem;
  color: #fff;
  padding-top: 1rem;
}

.logo {
  width: 8rem;
}

nav {
  margin-top: 1.5rem;
  align-items: center;
}

nav .navbar {
  display: flex;
  gap: 4rem;
}

.navbar li a {
  text-decoration: none;
  color: var(--color-text);
  font-size: 18px;
  font-weight: 500;
}

nav ul li {
  list-style-type: none;
}

.navbar li a:hover:not(.contact_us) {
  color: var(--primary-color);
  border-bottom: 3px solid var(--primary-color);
  padding-bottom: 10px;
  transition: all 0.15s linear;
}

header .navbar ul .stem_child {
  color: var(--color-text);
  padding-top: 0.2rem;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
}

header .navbar ul .stem_child:hover {
  color: var(--primary-color);

  transition: all 0.15s linear;
}

header .navbar ul .stem_child .stem_series_child {
  display: none;
}

header .navbar ul .stem_child .stem_series_child.active {
  background-color: var(--color-bg);
  position: absolute;
  margin: 1rem;
  display: flex;
  flex-direction: column;
  padding-bottom: 1.5rem;
  box-shadow: 0px 0px 10px var(--primary-color);
}

header .navbar ul .stem_child .stem_series_child li {
  padding-top: 1rem;
}

.contact_us {
  color: var(--secondary-color-on-text);
  background-color: var(--primary-color);
  padding: 11px;
  border-radius: 5px;
}

.contact_us:hover {
  color: white;
  background-color: var(--primary-color);
  outline: 1px solid var(--primary-color);
  border-radius: 5px;
}
/* End of styling Header section  */

@media screen and (max-width: 600px) {
  /* header styling */
  .menu-icon {
    display: inline-block;
  }
  .header .navbar {
    position: absolute;
    top: 70%;
    left: 0;
    right: 0;
    background: var(--color-bg);
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
    clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
    backdrop-filter: blur(4px);
    transition: all 0.5s ease-in-out;
  }

  .header .navbar.active {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
  }

  .header .navbar ul li {
    font-size: 2rem;
    margin: 1rem;
    display: block;
  }

  .header .navbar ul {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding-right: 2rem;
  }

  /* .navbar li a:hover:not(.contact_us) {
    color: none;
    border-bottom: none;
    padding-bottom: 7px;
    transition: all 0.15s linear;
  } */

  header .navbar ul .stem_child .stem_series_child.active {
    position: static;
    margin: 0.5rem;
    padding-bottom: 1rem;
  }

  header .navbar ul .stem_child {
    font-size: 18px;
  }

  .header .navbar ul li {
    margin: 0 0.6rem;
  }

  header .navbar ul .stem_child .stem_series_child li {
    padding-top: 0.2rem;
  }

  header .navbar ul .stem_child:hover {
    color: var(--primary-color);
    padding-bottom: 10px;
    transition: all 0.15s linear;
  }

  .arrows {
    width: 60px;
    height: 72px;
    position: absolute;
    left: 90%;
    margin-left: -30px;
    bottom: 120px;
  }

  .contact_us {
    color: var(--secondary-color-on-text);
    background-color: var(--primary-color);
    padding: 11px;
    border-radius: 5px;
  }

  .header .navbar ul li {
    padding-bottom: 15px;
  }

  /* Home styling */
}

@media screen and (max-width: 1000px) {
  /* header styling */
  .menu-icon {
    display: inline-block;
  }
  .header .navbar {
    position: absolute;
    top: 70%;
    left: 0;
    right: 0;
    background: var(--color-bg);
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
    clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
    backdrop-filter: blur(4px);
    transition: all 0.5s ease-in-out;
  }

  .header .navbar.active {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
  }

  .header .navbar ul li {
    font-size: 2rem;
    margin: 1rem;
    display: block;
  }

  .header .navbar ul {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding-right: 2rem;
  }

  /* .navbar li a:hover:not(.contact_us) {
    color: none;
    border-bottom: none;
    padding-bottom: 7px;
    transition: all 0.15s linear;
  } */

  header .navbar ul .stem_child .stem_series_child.active {
    position: static;
    margin: 0.5rem;
    padding-bottom: 1rem;
  }

  header .navbar ul .stem_child {
    font-size: 18px;
  }

  .header .navbar ul li {
    margin: 0 0.6rem;
  }

  header .navbar ul .stem_child .stem_series_child li {
    padding-top: 0.2rem;
  }

  header .navbar ul .stem_child:hover {
    color: var(--primary-color);
    padding-bottom: 10px;
    transition: all 0.15s linear;
  }

  .arrows {
    width: 60px;
    height: 72px;
    position: absolute;
    left: 90%;
    margin-left: -30px;
    bottom: 120px;
  }

  .contact_us {
    color: var(--secondary-color-on-text);
    background-color: var(--primary-color);
    padding: 11px;
    border-radius: 5px;
  }

  .header .navbar ul li {
    padding-bottom: 15px;
  }

  /* Home styling */
}
