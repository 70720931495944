.admin-container {
  padding: 20px;
  font-family: Arial, sans-serif;
}

.admin-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

.admin-title {
  text-align: center;
  color: #6b1d87;
  margin-bottom: 20px;
}

.admin-instructions {
  text-align: center;
  margin-bottom: 30px;
  color: #333;
}

.video-edit-section {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.video-item {
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 300px;
}

.video-item h3 {
  margin-bottom: 10px;
  color: #6b1d87;
}

.edit-container {
  display: flex;
  flex-direction: column;
}

.edit-input {
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
}

.edit-buttons {
  display: flex;
  justify-content: space-between;
}

.cancel-button {
  background-color: #d9534f;
}

.message {
  text-align: center;
  color: green;
  margin-bottom: 20px;
}
