/* Styling for the admin login page */

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: var(--color-bg);
}

.login-container p {
  cursor: pointer;
}
.login-container p:hover {
  color: #56126c;
}

.login-form {
  background-color: white;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
  text-align: center;
}

.login-form h1 {
  margin-bottom: 20px;
  color: #6b1d87;
}

.input-group {
  margin-bottom: 20px;
  text-align: left;
}

.input-group label {
  display: block;
  font-weight: bold;
  color: #6b1d87;
  margin-bottom: 8px;
}

.input-group input {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
  outline: none;
  transition: all 0.3s ease;
}

.input-group input:focus {
  border-color: #6b1d87;
  box-shadow: 0 0 5px rgba(107, 29, 135, 0.5);
}

.login-button {
  background-color: #6b1d87;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  width: 100%;
  transition: background-color 0.3s ease;
}

.login-button:hover {
  background-color: #56126c;
}

.error-message {
  color: red;
  margin-top: -10px;
  margin-bottom: 10px;
  font-size: 14px;
}

.contacts-container {
  margin-top: 20px;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
}

.contact-search {
  padding: 10px;
  width: 100%;
  margin-bottom: 20px;
  border-radius: 5px;
  border: 1px solid #ddd;
}

.contacts-list {
  max-height: 300px;
  overflow-y: auto;
}

.contact-item {
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.contact-item p {
  margin: 5px 0;
}

/* Animations */
.login-form {
  animation: fadeIn 0.7s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
