/* Styling for the contact form */

.contact-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.contact-heading {
  padding-top: 7rem;
  text-align: center;
  font-size: 2rem;
  color: #6b1d87; /* Purple theme */
  margin-bottom: 20px;
}

.contact-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.form-group {
  display: flex;
  flex-direction: column;
}

.form-group label {
  font-size: 1rem;
  color: #333;
  margin-bottom: 5px;
}

.form-group input,
.form-group textarea {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
}

.form-group input:focus,
.form-group textarea:focus {
  border-color: #6b1d87; /* Purple theme */
  outline: none;
}

.error {
  color: red;
  font-size: 0.8rem;
  margin-top: 5px;
}

.submit-btn {
  padding: 10px 15px;
  background-color: #6b1d87; /* Purple theme */
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.submit-btn:hover {
  background-color: #56126c;
}

.submit-btn:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

/* Styling for the marketing opt-in checkbox */
.marketing-opt-in {
  display: flex;
  align-items: center;
}

.marketing-opt-in label {
  font-size: 0.9rem;
  margin-left: 10px;
  color: #6b1d87; /* Purple theme */
}

.marketing-opt-in input {
  width: 20px;
  height: 20px;
  cursor: pointer;
  accent-color: #6b1d87; /* Purple theme for checkbox */
}
