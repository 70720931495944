/* Styling for the Admin Marketing Page */
.marketing-container {
  padding: 40px;
  max-width: 600px;
  margin: 0 auto;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

h2 {
  text-align: center;
  color: #6b1d87;
  margin-bottom: 20px;
}

.contact-form {
  display: flex;
  flex-direction: column;
}

.input-group {
  margin-bottom: 20px;
}

.input-group label {
  font-weight: bold;
  color: #6b1d87;
  margin-bottom: 8px;
  display: block;
}

.input-group input,
.input-group textarea {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
  outline: none;
  transition: all 0.3s ease;
}

.input-group input:focus,
.input-group textarea:focus {
  border-color: #6b1d87;
  box-shadow: 0 0 5px rgba(107, 29, 135, 0.5);
}

.buttons-group {
  display: flex;
  justify-content: space-between;
}

.send-button,
.add-all-button,
.toggle-contacts-button {
  background-color: #6b1d87;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.send-button:hover,
.add-all-button:hover,
.toggle-contacts-button:hover {
  background-color: #56126c;
}

.status-message {
  text-align: center;
  margin-top: 10px;
  font-size: 14px;
  color: green;
}

/* Styling for the contacts section */
.contacts-container {
  margin-top: 20px;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.contact-search {
  padding: 10px;
  width: 100%;
  margin-bottom: 20px;
  border-radius: 5px;
  border: 1px solid #ddd;
}

.contacts-list {
  max-height: 300px;
  overflow-y: auto;
}

.contact-item {
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.contact-item p {
  margin: 5px 0;
}

.toggle-contacts-button {
  margin-top: 20px;
  width: 100%;
}
