.gallery_show {
  padding: 5px;
}

/* Gallery Grid */
.gallery-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 15px;
  margin-top: 20px;
}

.gallery-item {
  cursor: pointer;
}

.gallery-image {
  width: 100%;
  height: 200px; /* Ensure all images have the same height */
  object-fit: cover; /* Make sure the image fits nicely */
  border-radius: 10px;
  transition: transform 0.3s ease;
}

/* Slideshow Overlay */
.slideshow-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.slideshow-content {
  position: relative;
}

.slideshow-image {
  max-width: 80vw;
  max-height: 80vh;
  border-radius: 10px;
}

.close-slideshow {
  position: absolute;
  top: 10px;
  right: 20px;
  font-size: 2rem;
  color: white;
  background: transparent;
  border: none;
  cursor: pointer;
}

/* Previous and Next Buttons */
.prev-button,
.next-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 2rem;
  background: rgba(255, 255, 255, 0.7);
  border: none;
  cursor: pointer;
  padding: 10px;
  border-radius: 50%;
}

.prev-button {
  left: 10px;
}

.next-button {
  right: 10px;
}

/* Show More Button */
.show-more-button {
  display: block;
  margin: 20px auto;
  padding: 10px 20px;
  background-color: #6b1d87;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  font-size: 1rem;
}

.show-more-button:hover {
  background-color: #56126c;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  /* Adjust grid layout for smaller screens */
  .gallery-grid {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 10px;
  }

  /* Ensure images are properly scaled on small screens */
  .gallery-image {
    border-radius: 5px;
  }

  /* Adjust slideshow image size for mobile */
  .slideshow-image {
    max-width: 90vw;
    max-height: 80vh;
  }

  /* Adjust close button for mobile */
  .close-slideshow {
    font-size: 1.5rem;
    right: 10px;
  }

  /* Adjust "Show More" button for mobile */
  .show-more-button {
    font-size: 0.9rem;
    padding: 8px 15px;
  }
}

@media (max-width: 480px) {
  /* Smaller devices like mobile phones */
  .gallery-grid {
    grid-template-columns: 1fr;
  }

  .gallery-image {
    width: 100%;
    height: auto;
  }

  .slideshow-image {
    max-width: 85vw;
    max-height: 70vh;
  }

  .close-slideshow {
    font-size: 1.2rem;
  }
}
