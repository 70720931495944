@media only screen and (max-width: 767px) {
}

@media screen and (max-width: 600px) {
  /* Home styling */
  section {
    width: 100%;
  }

  .stem_section {
    margin: auto;
    background: var(--color-bg);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    padding-top: 5rem;
    height: max-content;
  }

  /* .home {
    margin: auto;
    background: var(--color-bg);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    padding-top: 5rem;
    height: max-content;
  } */

  .stem_logo_Home {
    width: 20rem;
  }

  .stem_video {
    margin: 3rem 2rem;
    padding: 20px;
  }

  .video-grid {
    grid-template-columns: 1fr;
  }

  #video-container {
    margin: 3rem 2rem;
    padding: 20px;
  }

  section {
    width: 100%;
  }
  .home_text {
    font-size: 1.5rem;
  }

  .arrows {
    margin-left: -70px;
    bottom: -5rem;
    left: 55%;
  }

  /* about us section */

  .about_content {
    margin: auto;
    background: var(--color-bg);
    padding-top: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    text-decoration: none;
  }

  .about-us {
    padding-top: 2rem;
  }

  .order1 {
    order: 2;
  }

  .order2 {
    order: 1;
  }
  .stem_intro {
    width: 100%;
    font-size: 1.5rem;
    padding-top: 2rem;
    padding-left: 2rem;
  }
  .about-sub_text {
    width: 100%;
    font-size: 1.5rem;
    padding-top: 2rem;
    padding-left: 2rem;
  }

  /* stem series styling */

  /* OUR TV CHANNEL */
  .channels ul li {
    font-size: 1rem;
    color: var(--color-text);
    padding-bottom: 2rem;
    list-style-type: none;
  }

  /* Footer styling */

  footer {
    width: 100%;
    height: 20%;
    background-color: var(--primary-color);
    margin-top: 5rem;
    display: grid;
    place-items: center;
    padding-top: 2rem;
    padding-bottom: 4rem;
    padding-right: 2rem;
    padding-left: 2rem;
  }

  footer h1 {
    color: #fff;
  }

  footer ul li {
    list-style-type: none;
  }

  .social_links {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  footer ul li a {
    text-decoration: none;
    font-size: 2rem;
    transition: 0.5s;
    color: #fff;
  }

  footer ul li a:hover {
    color: var(--color-bg);
  }

  .copyright {
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 1000px) {
  /* Home styling */
  section {
    width: 100%;
  }

  .stem_section {
    margin: auto;
    background: var(--color-bg);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    padding-top: 5rem;
    height: max-content;
  }

  .home {
    margin: auto;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 5rem;
  }

  .stem_logo_Home {
    width: 20rem;
  }

  .stem_video {
    margin: 3rem 2rem;
    padding: 20px;
  }

  .video-grid {
    grid-template-columns: 1fr;
  }

  #video-container {
    margin: 3rem 2rem;
    padding: 20px;
  }

  .home_text {
    font-size: 1.5rem;
  }

  /* arrow styling */
  .arrows {
    margin-left: -50px;
    bottom: -22rem;
  }

  /* about us section */

  .about_content {
    margin: auto;
    background: var(--color-bg);
    padding-top: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    text-decoration: none;
  }

  .about-us {
    padding-top: 4rem;
  }

  .order1 {
    order: 2;
  }

  .order2 {
    order: 1;
  }
  .stem_intro {
    width: 100%;
    font-size: 1.5rem;
    padding-top: 2rem;
    padding-left: 2rem;
  }
  .about-sub_text {
    width: 100%;
    font-size: 1.5rem;
    padding-top: 2rem;
    padding-left: 2rem;
  }

  /* stem series styling */

  .card_container {
    grid-template-columns: 1fr;
  }

  .stem_img {
    width: 100%;
  }
}
