* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

:root {
  font-size: 16px;
  --color-text: #fff;
  --color-bg: #a68cc9;
  --color-bg-alt: #a68cc9;
  --color-link: rgba(255, 255, 255, 0.5);
  --color-link-hover: #fff;
  --color-title: #e93f33;
  --primary-color: #c5057d;
}

html {
  font-family: "tenon", sans-serif;
  scroll-behavior: smooth;
  scroll-padding-top: 7rem;
  overflow-x: hidden;
}

html::-webkit-scrollbar {
  width: 1rem;
}

html::-webkit-scrollbar-thumb {
  background: var(--primary-color);
  border-radius: 5rem;
}

html::-webkit-scrollbar-track {
  background: #fff;
}

body {
  background-color: var(--color-bg);
  font-family: "stinger-variable", sans-serif;
}

/* Main container and section styling */
main {
  width: 100%;
  /* background-color: red; */
}
.soon {
  padding-top: 8rem;
  font-size: 3em;
  text-align: center;
  color: #fff;
}
section {
  width: 90%;
  /* height: 100vh; */
}

/* Beginning of Home section styling  */
.home {
  margin: auto;
  margin-top: 9rem;
  background: var(--color-bg);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  padding: 2rem;
  text-align: center;
  box-shadow: 0px 0px 10px var(--primary-color);
}
table {
  margin-left: auto;
  margin-right: auto;
}

table.purpleHorizon {
  border: 4px solid #792396;
  background-color: #555555;
  width: 80%;
  text-align: center;
  border-collapse: collapse;
}
table.purpleHorizon td,
table.purpleHorizon th {
  border: 1px solid #555555;
  padding: 5px 2px;
}
table.purpleHorizon tbody td {
  font-size: 1.5rem;
  font-weight: bold;
  color: #ffffff;
}
table.purpleHorizon tr:nth-child(even) {
  background: #cc3bfc;
}
table.purpleHorizon thead {
  background: #792396;
  border-bottom: 4px solid #792396;
}
table.purpleHorizon thead th {
  font-size: 2rem;
  font-weight: bold;
  color: #ffffff;
  text-align: center;
  border-left: 2px solid #792396;
}
table.purpleHorizon thead th:first-child {
  border-left: none;
}

.link {
  color: var(--primary-color);
  text-decoration: none !important;
}

.stem_section {
  width: 90%;
  height: 100vh;
  margin: auto;
  background: var(--color-bg);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  text-align: center;
}

.stem_video {
  width: 100%;
  max-width: 700px; /* Limit the width of the video container */
  margin: 3rem auto; /* Center the container horizontally */
  padding: 20px;
  box-sizing: border-box;
  text-align: center;
  border: 2px solid var(--primary-color); /* Example border */
  border-radius: 10px; /* Example border radius */
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
}

.stem_video iframe {
  width: 100%;
  height: 70; /* Maintain aspect ratio */
  border: none; /* Remove iframe border */
}

.stem_intro {
  width: 45%;
  word-spacing: 3px;
  line-height: 2.2rem;
  margin: auto;
  font-family: "stinger-variable", sans-serif;
  font-size: 2rem;
  /* opacity: 0.5; */
  color: var(--color-text);
}

.video-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  padding: 20px;
}

.video {
  position: relative;
  overflow: hidden;
  padding-top: 56.25%; /* 16:9 aspect ratio */
}

.video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#video-container {
  width: 100%;
  max-width: 800px; /* Limit the width of the video container */
  margin: 3rem auto; /* Center the container horizontally */
  padding: 20px;
  box-sizing: border-box;
  text-align: center;
  border: 2px solid var(--primary-color); /* Example border */
  border-radius: 10px; /* Example border radius */
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
}

#video-container iframe {
  width: 100%;
  height: 70; /* Maintain aspect ratio */
  border: none; /* Remove iframe border */
}

#video-container h2 {
  margin-top: 13px;
  color: var(--color-text);
  font-size: 1rem;
  word-spacing: 0.4rem;
  line-height: 1.4rem;
}

.stem_logo_Home {
  width: 33rem;
}

.home_text {
  font-family: "stinger-variable", sans-serif;
  font-variation-settings: "wdth" 140, "wght" 300;
  font-weight: 300;
  font-size: 2.5rem;
  color: #fff;
  padding-bottom: 2rem;
}

.home_btn {
  font-size: 1.2rem;
  padding: 1rem 2.5rem;
  border: none;
  outline: none;
  border-radius: 0.4rem;
  cursor: pointer;
  text-transform: uppercase;
  text-decoration: none;
  background-color: rgb(14, 14, 26);
  color: rgb(234, 234, 234);
  font-weight: 700;
  transition: 0.6s;
  box-shadow: 0px 0px 60px #c5057d;
}

.home_btn:active {
  scale: 0.92;
}

.home_btn:hover {
  background: rgb(2, 29, 78);
  background: linear-gradient(
    270deg,
    rgba(157, 29, 119, 0.681) 0%,
    rgba(168, 57, 164, 0.873) 60%
  );
  color: rgb(4, 4, 38);
}
/* End of styling Home page  */

/* arrow styling */
.arrows {
  width: 60px;
  height: 72px;
  position: absolute;
  left: 50%;
  margin-left: -50px;
  bottom: -12rem;
}

.arrows path {
  stroke: var(--primary-color);
  fill: transparent;
  stroke-width: 5px;
  animation: arrow 2s infinite;
  -webkit-animation: arrow 2s infinite;
}

@keyframes arrow {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes arrow /*Safari and Chrome*/ {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.arrows path.a1 {
  animation-delay: -1s;
  -webkit-animation-delay: -1s; /* Safari 和 Chrome */
}

.arrows path.a2 {
  animation-delay: -0.5s;
  -webkit-animation-delay: -0.5s; /* Safari 和 Chrome */
}

.arrows path.a3 {
  animation-delay: 0s;
  -webkit-animation-delay: 0s; /* Safari 和 Chrome */
}
/* arrow styling ends here */

/* About us section sytling */
.about-us {
  margin: auto;
  padding-top: 9rem;
  padding-left: 2rem;
  padding-right: 2rem;
  margin-bottom: 3rem;
  /* box-shadow: 0px 0px 10px var(--primary-color); */
}

.about-us_heading {
  font-family: "stinger-variable", sans-serif;
  font-variation-settings: "wdth" 140, "wght" 150;
  font-size: 3rem;
  font-style: italic;
  padding-bottom: 2rem;
  text-align: center;
  color: var(--color-text);
}

.about-us_img {
  /* padding: 2rem; */
  width: 100%;
  border-radius: 40px;
}

.about_content {
  margin: auto;
  background: var(--color-bg);
  display: flex;
  gap: 2rem;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  text-align: left;
  text-decoration: none;
}

.about-sub_text {
  width: 45%;
  word-spacing: 3px;
  line-height: 2.2rem;
  margin: auto;
  font-family: "stinger-variable", sans-serif;
  font-size: 2rem;
  color: var(--color-text);
}

/* Stem series section styling  */
.stem_series {
  margin: auto;
  height: max-content;
  padding-top: 2rem;
  padding-left: 2rem;
  padding-right: 2rem;
  box-shadow: 0px 0px 10px var(--primary-color);
}

.stem_heading {
  font-family: "stinger-variable", sans-serif;
  font-variation-settings: "wdth" 140, "wght" 150;
  font-size: 2.5rem;
  font-style: italic;
  text-align: center;
  color: var(--color-text);
}

.stem_content {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 3rem;
  padding-bottom: 4rem;
}

.stem_img {
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
}

/* stem series card container styling */

.card_container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  /* place-content: center; */
  /* place-items: center; */
  /* background-color: #e93f33; */
}

.card_container .card {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%;
  height: max-content;
  background-color: #fff;
  margin: 30px 10px;
  padding: 20px 15px;
  box-shadow: 0 5px 202px rgba(0, 0, 0, 0.5);
  transition: 0.3s ease-in-out;
  box-shadow: 0px 0px 10px var(--primary-color);
}

/* .card_container .card:hover {
  height: 80vh;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
} */

.card_container .card .imgBx {
  width: 100%;
  position: relative;
  top: -60px;
  z-index: 1;
}

.cards_img {
  max-width: 100%;
  max-height: 100%;
  border-radius: 6px;
}

.card_container .card .card_content {
  /* visibility: hidden; */
  visibility: visible;
  opacity: 1;
  text-align: center;
  margin-top: -30px;
  transition-delay: 0.3s;
  color: var(--primary-color);
}

/* .card_container .card:hover .card_content {
  visibility: visible;
  opacity: 1;
  margin-top: -60px;
  transition-delay: 0.3s;
  color: var(--primary-color);
} */
/* End of card container styling */

/* TV AND YOUTUBE SECTION */
.tv_channel {
  display: flex;
  align-items: center;
  margin: auto;
  padding-top: 4rem;
}

.channels {
  width: 100%;
}

.channels h3 {
  font-size: 2rem;
  color: var(--primary-color);
  padding-bottom: 2rem;
  text-align: center;
  text-decoration: underline;
}

.channels ul {
  text-align: center;
  color: var(--color-text);
}

.channels ul li {
  font-size: 1.5rem;
  color: var(--color-text);
  padding-bottom: 2rem;
  list-style-type: none;
}

/* Subme Form styling */
.sub_me {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.contact_me {
  height: 260px;
  width: 440px;
  padding: 20px;
  position: relative;
  background-color: #fff;
  border-radius: 4px;
  color: var(--primary-color);
  box-shadow: 0px 0px 60px 5px rgba(0, 0, 0, 0.4);
}

.contact_me h3 {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 4px;
  line-height: 28px;
}

.contact_me p {
  margin: 20px 0px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;

  line-height: 16px;
}

.contact_me input {
  border: none;
  border-bottom: 1px solid #d4d4d4;
  padding: 10px;
  width: 82%;
  background: transparent;
  transition: all 0.25s ease;
}

.contact_me input:focus {
  outline: none;
  border-bottom: 1px solid var(--primary-color);
  font-style: italic;
  font-weight: bold;
}

.contact_me .sub_btn {
  position: absolute;
  border-radius: 30px;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  background-color: var(--primary-color);
  color: #fff;
  padding: 12px 25px;
  display: inline-block;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 5px;
  right: -10px;
  bottom: -20px;
  cursor: pointer;
  transition: all 0.25s ease;
  box-shadow: -5px 6px 20px 0px rgba(26, 26, 26, 0.4);
}

.contact_me .sub_btn:hover {
  color: var(--primary-color);
  background-color: #fff;
  border-color: var(--primary-color);
  box-shadow: -5px 6px 20px 0px rgba(88, 88, 88, 0.569);
}

.scroll-buttons {
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  z-index: 1000;
}

.scroll-btn {
  background-color: #c5057d;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.scroll-btn:hover {
  background-color: #a03b60;
}

/* Footer styling */

footer {
  width: 100%;
  background-color: var(--primary-color);
  margin-top: 5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 2rem;
  padding-bottom: 4rem;
}

footer h1 {
  color: #fff;
}

footer ul li {
  list-style-type: none;
}

.social_links {
  display: flex;
  flex-direction: row;
  gap: 4rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

footer ul li a {
  text-decoration: none;
  font-size: 3rem;
  transition: 0.5s;
  color: #fff;
}

footer ul li a:hover {
  color: var(--color-bg);
}

.copyright {
  font-size: 1rem;
}
